<template>
  <v-container id="data-tables-view" fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-col class="text-right">
      <!-- <app-btn class="mx-2" @click="dialogBulkDeviceUpdate = true">
        <v-icon>mdi-view-grid-plus</v-icon> Set Device Settings
      </app-btn> -->
    </v-col>
    <material-card
      icon="mdi-cellphone-cog"
      icon-small
      color="primary"
      title="Devices"
    >
      <v-card-text>
        <v-row>
          <!-- <v-col class="text-left">
            <v-switch v-model="singleExpand" label="Single expand" />
          </v-col> -->
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class="ml-auto"
              hide-details
              label="Search records"
              single-line
              style="max-width: 250px"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3" />
        <!--
          :options.sync="options"
          :server-items-length="totalDevices"
          :loading="loading"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-select
          v-model="selectedDevices"
          :show-expand="isExpand"
          :items-per-page="5"
          -->
        <v-data-table
          :headers="headers"
          :items="mobileStatuses"
          key="id"
          :search.sync="search"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
          :items-per-page="5"
          show-expand
          single-expand
          :expanded.sync="expanded"
          item-key="id"
          @item-expanded="(data) => getDeviceLogs(data)"
        >
          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogMessages" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Messages</span>
                </v-card-title>
                <v-card-text>
                  <v-row class="mx-2 mt-2">
                    <v-col md="10">
                      <v-text-field v-model="message" label="Message" />
                    </v-col>
                    <v-col md="2" class="mt-2">
                      <v-btn color="primary" @click="addMessage">
                        {{ btn }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-card
                    v-for="message in messages"
                    :key="message.id"
                    color="#E8F5E9"
                    class="ma-1 pa-1"
                  >
                    <v-row class="ma-1">
                      <v-col md="10">
                        {{ message.description }}
                      </v-col>
                      <v-col md="2">
                        <v-icon
                          small
                          color="info"
                          class="mr-2"
                          @click="editMessage(message)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          color="error"
                          @click="deleteMessage(message)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogMessages = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRequiredVersion" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Required Version</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentRequiredVersion"
                        :items="appVersions"
                        item-text="fileName"
                        item-value="fileName"
                        label="Required Version"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogRequiredVersion = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="updateRequiredVersion"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogFtpProfile" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">FTP Profile</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentFtpProfile"
                        :items="ftpProfiles"
                        item-text="name"
                        item-value="id"
                        label="FTP Profile"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogFtpProfile = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="updateFtpProfile">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCurrentServer" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Current Server</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-autocomplete
                        v-model="currentServer"
                        :items="serverUrls"
                        item-text="value"
                        item-value="id"
                        label="Current Server Url"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCurrentServer = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="updateServerUrl">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogBulkDeviceUpdate" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Device Settings</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentRequiredVersion"
                          :items="appVersions"
                          item-text="fileName"
                          item-value="fileName"
                          label="Required Version"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentFtpProfile"
                          :items="ftpProfiles"
                          item-text="name"
                          item-value="id"
                          label="FTP Profile"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="currentServer"
                          :items="serverUrls"
                          item-text="value"
                          item-value="id"
                          label="Current Server Url"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-row>
                          <div class="ml-2 mt-2">Require Download:</div>
                          <v-icon
                            v-if="currentRequiredDownload"
                            color="primary"
                            large
                            @click="isRequiredDownload"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-icon
                            v-else
                            color="error"
                            large
                            @click="isRequiredDownload"
                          >
                            mdi-close-circle
                          </v-icon>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogBulkDeviceUpdate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="bulkDeviceUpdate">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Update device</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="editedItem.name" label="IMEI" />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.description"
                          label="SIM"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.vehicleNo"
                          label="Rego"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.driverId"
                          :items="drivers"
                          item-text="username"
                          item-value="driverId"
                          label="Driver"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="updateDevice">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <div class="text-center" v-if="item.deviceModel === 'SM-T515'">
              <img src="../assets/SM-T515.png" width="100" />
            </div>
            <div class="text-center" v-else-if="item.deviceModel === 'SM-T505'">
              <img src="../assets/SM-T515.png" width="100" />
            </div>
            <div
              class="text-center"
              v-else-if="item.deviceModel === 'SM-M315F'"
            >
              <img src="../assets/SM-M315F.jpeg" width="100" />
            </div>
            <div class="text-center" v-else>
              <img src="../assets/defaultDevice.png" width="100" />
            </div>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <div>
              {{
                item.locations && item.locations.length > 0
                  ? item.locations[0].address
                  : ""
              }}
            </div>
          </template>
          <template v-slot:[`item.ownedDriverId`]="{ item }">
            <div>{{ getDriverName(item.ownedDriverId) }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <!-- <template v-slot:[`item.messages`]="{ item }">
            <v-chip
              class="ma-2"
              text-color="white"
              color="primary"
              @click="openMessageDialog(item.id, item.messages)"
            >
              {{ item.messages.length }}
            </v-chip>
          </template> -->
          <template v-slot:[`item.requiredVersion`]="{ item }">
            <v-chip
              color="primary"
              dark
              @click="openDialogRequiredVersion(item)"
            >
              {{ item.requiredVersion }}
            </v-chip>
          </template>
          <template v-slot:[`item.requireDownload`]="{ item }">
            <v-icon
              v-if="item.requireDownload"
              color="primary"
              large
              @click="updateRequireDownload(item, false)"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else
              color="error"
              large
              @click="updateRequireDownload(item, true)"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <template v-slot:[`item.ftpConfiguration`]="{ item }">
            <v-chip color="primary" dark @click="openDialogFtpProfile(item)">
              {{ item.ftpConfiguration ? item.ftpConfiguration.name : "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.currentServer`]="{ item }">
            <v-chip color="primary" dark @click="openDialogCurrentServer(item)">
              {{ item.currentServer ? item.currentServer.name : "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.lastConnected`]="{ item }">
            <div>{{ timeFormat(item.lastConnected) }}</div>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length" class="primary pa-1">
              <v-data-table
                :headers="log_header"
                :items="logs"
                :options.sync="log_options"
                :server-items-length="totalLogs"
                :loading="log_loading"
                :items-per-page="5"
                :footer-props="{
                  showFirstLastPage: true,
                  'items-per-page-options': [5, 10, 15],
                }"
              >
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
export default {
  name: "Settings",
  components: { Loading },
  data: () => ({
    dialogDelete: false,
    currentMacAddress: "",
    currentRequiredVersion: "",
    currentFtpProfile: "",
    dialog: false,
    dialogFtpProfile: false,
    dialogRequiredVersion: false,
    dialogCurrentServer: false,
    dialogBulkDeviceUpdate: false,
    sortBy: "lastConnected",
    locationSortBy: "time",
    isLoading: false,
    options: {},
    totalDevices: 0,
    loading: false,
    loadingSize: 128,
    loadingFullPage: false,
    sortDesc: true,
    appSearch: "",
    log_header: [
      { text: "Active", value: "active" },
      { text: "Battery status", value: "batteryStatus" },
      { text: "Address", value: "address" },
      { text: "Created at", value: "createdAt" },
    ],
    currentDeviceId: "",
    logs: [],
    log_options: {},
    totalLogs: 0,
    log_loading: false,
    selectedDevices: [],
    headers: [
      {
        text: "Image",
        sortable: false,
        value: "image",
      },
      // { text: "Device Id", sortable: false, value: "macAddress" },
      { text: "IMEI", sortable: false, value: "name" },
      { text: "SIM", sortable: false, value: "description" },
      { text: "Rego", sortable: false, value: "vehicleNo" },
      { text: "Driver", sortable: false, value: "ownedDriverId" },
      { text: "Model", sortable: false, value: "deviceModel" },
      { text: "OS", sortable: false, value: "os" },
      { text: "App Version", sortable: false, value: "appVersion" },
      // {
      //   text: "Required App Version",
      //   sortable: false,
      //   value: "requiredVersion",
      // },
      // { text: "Require Update", sortable: false, value: "requireDownload" },
      // { text: "FTP Profile", sortable: false, value: "ftpConfiguration" },
      // { text: "Current Server", sortable: false, value: "currentServer" },
      // { text: "User", sortable: false, value: "username" },
      // { text: "IP Address", sortable: false, value: "ipAddress" },
      { text: "Signal Strength", sortable: false, value: "signalStrength" },
      // { text: "BSSID", sortable: false, value: "bssid" },
      // { text: "Link Speed", sortable: false, value: "linkSpeed" },
      { text: "ESSID", sortable: false, value: "essid" },
      { text: "Address", sortable: false, value: "address" },
      { text: "Last Connected", sortable: false, value: "lastConnected" },
      { text: "Actions", sortable: false, value: "actions" },
    ],
    isExpand: true,
    expanded: [],
    appVersions: [],
    ftpProfiles: [],
    serverUrls: [],
    singleExpand: true,
    dialogMessages: false,
    btn: "",
    mobileStatuses: [],
    messages: [],
    message: "",
    currentMessage: "",
    currentDevice: "",
    currentServer: "",
    currentRequiredDownload: false,
    search: "",
    awaitingSearch: false,
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      vehicleNo: "",
      driverId: 0,
    },
  }),
  computed: {
    drivers() {
      return [
        { username: "No Driver", driverId: null },
        ...this.$store.state.taxi.activeDrivers,
      ];
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getDataFromApi(this.search);
      },
      deep: true,
    },
    log_options: {
      handler() {
        this.deviceLogs(this.currentDeviceId);
      },
      deep: true,
    },
    search: {
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getDataFromApi(this.search);
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    getDataFromApi(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        Start: itemsPerPage * (page - 1),
        Length: itemsPerPage,
        Search: search,
        SortColumnName: "LastConnected",
        SortDirection: "desc",
      };
      // this.isLoading = true;
      // SettingsDataService.getDevices(data)
      //   .then((response) => {
      //     // this.isLoading = false;
      //     this.totalDevices = response.data.totalDevices;
      //     this.mobileStatuses = response.data.devices;
      //     this.loading = false;
      //   })
      //   .catch(() => {
      //     // this.isLoading = false;
      //     this.loading = false;
      //   });
    },
    getDriverName(id) {
      let driver = this.drivers.find((x) => x.driverId === id);
      return driver ? driver.username : "";
    },
    initialize() {
      // SettingsDataService.getAppVersions().then((response) => {
      //   this.appVersions = response.data;
      // });
      // SettingsDataService.getAllFtpConfig().then((response) => {
      //   this.ftpProfiles = response.data;
      // });
      // SettingsDataService.getServerUrls().then((response) => {
      //   this.serverUrls = response.data;
      // });
      this.getAllDevices();
    },
    getAllDevices() {
      this.$http
        .get("/Device")
        .then((response) => {
          this.mobileStatuses = response.data;
        })
        .catch(() => {});
    },
    editItem(item) {
      //console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    updateDevice() {
      this.$http
        .post("/Device", this.editedItem)
        .then((response) => {
          if (response.data.isSuccess) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
          this.initialize();
        })
        .catch(() => {});
      this.dialog = false;
    },
    openMessageDialog(id, messages) {
      this.dialogMessages = true;
      this.btn = "Add";
      this.message = "";
      this.messages = messages;
      this.currentDevice = id;
    },
    editMessage(message) {
      this.btn = "Edit";
      this.message = message.description;
      this.currentMessage = message.id;
    },
    addMessage() {
      if (this.btn === "Edit") {
        var data = {
          id: this.currentMessage,
          mobileStatusId: this.currentDevice,
          description: this.message,
          active: 1,
        };
        // SettingsDataService.update(data).then((response) => {
        //   var message = this.mobileStatuses.find(
        //     (x) => x.id === this.currentDevice
        //   ).messages;
        //   var index = message.findIndex((x) => x.id === this.currentMessage);
        //   this.messages.splice(index, 1, data);
        // });
        this.btn = "Add";
      } else {
        var data = {
          mobileStatusId: this.currentDevice,
          description: this.message,
          active: 1,
        };
        // SettingsDataService.create(data).then((response) => {
        //   this.mobileStatuses
        //     .find((x) => x.id == this.currentDevice)
        //     .messages.push(response.data);
        // });
      }
      this.message = "";
    },
    deleteMessage(message) {
      // SettingsDataService.delete(message.id).then((response) => {
      //   var message = this.mobileStatuses.find(
      //     (x) => x.id === this.currentDevice
      //   ).messages;
      //   var index = message.findIndex((x) => x.id === this.currentMessage);
      //   this.messages.splice(index, 1);
      // });
    },
    getLicence(id) {
      var device = this.mobileStatuses.find((x) => x.id === id);
      if (device) {
        return device.licenses;
      }
    },
    timeFormat(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
    openDialogRequiredVersion(item) {
      this.currentRequiredVersion = "";
      this.currentMacAddress = item.macAddress;
      this.dialogRequiredVersion = true;
    },
    openDialogFtpProfile(item) {
      this.currentMacAddress = item.macAddress;
      this.dialogFtpProfile = true;
    },
    openDialogCurrentServer(item) {
      this.currentMacAddress = item.macAddress;
      this.dialogCurrentServer = true;
    },
    updateRequiredVersion() {
      var data = {
        macAddress: this.currentMacAddress,
        requiredVersion: this.currentRequiredVersion,
      };
      // SettingsDataService.updateRequiredVersion(data).then((response) => {
      //   if (response.data.isSuccess) {
      //     const index = this.mobileStatuses.findIndex(
      //       (x) => x.macAddress == this.currentMacAddress
      //     );
      //     if (index > -1) {
      //       const device = this.mobileStatuses[index];
      //       device.requiredVersion = this.currentRequiredVersion;
      //       this.$set(this.mobileStatuses, index, device);
      //     }
      //     this.$toast.success(
      //       "Required status updated successfully.",
      //       "Success",
      //       {
      //         position: "topRight",
      //       }
      //     );
      //   }
      // });
      this.dialogRequiredVersion = false;
    },
    updateRequireDownload(item, isRequired) {
      var data = {
        macAddress: item.macAddress,
        requireDownload: isRequired,
      };
      // SettingsDataService.updateRequiredDownload(data).then((response) => {
      //   if (response.data.isSuccess) {
      //     const index = this.mobileStatuses.findIndex(
      //       (x) => x.macAddress == item.macAddress
      //     );
      //     if (index > -1) {
      //       const device = this.mobileStatuses[index];
      //       device.requireDownload = isRequired;
      //       this.$set(this.mobileStatuses, index, device);
      //     }
      //     this.$toast.success(
      //       "Required status updated successfully.",
      //       "Success",
      //       {
      //         position: "topRight",
      //       }
      //     );
      //   }
      // });
    },
    updateFtpProfile() {
      var data = {
        macAddress: this.currentMacAddress,
        ftpConfigurationId: this.currentFtpProfile,
      };
      // SettingsDataService.updateDeviceFtpConfigurations(data).then(
      //   (response) => {
      //     if (response.data.isSuccess) {
      //       this.$toast.success(
      //         "Required status updated successfully.",
      //         "Success",
      //         {
      //           position: "topRight",
      //         }
      //       );
      //       this.getDataFromApi();
      //     }
      //   }
      // );
      this.dialogFtpProfile = false;
    },
    updateServerUrl() {
      var data = {
        macAddress: this.currentMacAddress,
        settingId: this.currentServer,
      };
      // SettingsDataService.updateCurrentServerUrl(data).then((response) => {
      //   if (response.data.isSuccess) {
      //     this.$toast.success("Server url updated successfully.", "Success", {
      //       position: "topRight",
      //     });
      //     this.getDataFromApi();
      //   }
      //   this.dialogCurrentServer = false;
      // });
    },
    isRequiredDownload() {
      this.currentRequiredDownload = !this.currentRequiredDownload;
    },
    bulkDeviceUpdate() {
      var macAddressList = [];
      this.selectedDevices.forEach((x) => {
        macAddressList.push(x.macAddress);
      });
      var data = {
        macAddresses: macAddressList,
        settingId: this.currentServer,
        ftpConfigurationId: this.currentFtpProfile,
        requireDownload: this.currentRequiredDownload,
        requiredVersion: this.currentRequiredVersion,
      };
      // SettingsDataService.updateDeviceConfigurations(data).then((response) => {
      //   if (response.data.isSuccess) {
      //     this.$toast.success("Server url updated successfully.", "Success", {
      //       position: "topRight",
      //     });
      //     this.getDataFromApi();
      //   }
      // });
      this.dialogBulkDeviceUpdate = false;
    },
    deviceLogs(id) {
      this.log_loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.log_options;
      var data = {
        skip: itemsPerPage * (page - 1),
        take: itemsPerPage,
        searchColumn: "DeviceId",
        searchValue: id,
      };
      this.$http
        .post("/Device/ActiveLogs", data)
        .then((response) => {
          this.logs = response.data.data.data;
          this.totalLogs = response.data.data.total;
          this.log_loading = false;
        })
        .catch((error) => {
          this.log_loading = false;
        });
    },
    deleteItem(item) {
      this.editedIndex = this.mobileStatuses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    getDeviceLogs(data) {
      if (data.value) {
        this.currentDeviceId = data.item.id;
        this.deviceLogs(this.currentDeviceId);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http.delete(`/Device/${this.editedItem.id}`).then((response) => {
        if (response.data.success) {
          this.mobileStatuses.splice(index, 1);
          this.$toast.success(response.data.message, "Success", {
            position: "topRight",
          });
        } else {
          this.$toast.error(response.data.message, "Error", {
            position: "topRight",
          });
        }
      });
      this.closeDelete();
    },
  },
};
</script>
